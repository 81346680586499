import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, respondTo, breakpoints, css } from '@styles';
import dateFormat from 'dateformat';
import Image from 'gatsby-image';
import { Link } from '@utils';
import { Blog, NewsSettings } from '@content/types/blog';
import { colors, dimensions } from '@styles';
import * as utils from '@utils';
import ButtonLink from '@components/common/ButtonLink';

import clockIcon from '@assets/svg/feature-incl-icon-2.svg';
import { HomepageRecommendedBlogPostsItem } from '@content/types/homepage';

const ArticleItem = styled.div<ArticleItemProps>`
  width: ${props =>
    props.row && props.row > 0 ? `calc(100% / ${props.row} - 15px)` : 'calc(100% / 2 - 15px)'};
  height: ${props => props.heightAuto ? 'auto' : '100%'};
  background-color: ${colors.grayNurse};
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  ${respondTo(
    breakpoints.md,
    css`
      width: 100%;
    `
  )}
`;

const ArticleImage = styled(Image)`
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 52%;
`;

const ArticleContent = styled.div`
  padding: 25px;
  
  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 25px 35px 40px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `
  )}
`;

const ArticleCategories = styled.div`
  margin-bottom: 10px;

  a {
    display: inline-block;
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    margin-right: 10px;
  }
`;

const ArticleCategory = styled(Link)`
  color: ${colors.text.article};
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
`;

const ArticleTag = styled(Link)`
  color: ${colors.green.medium};
`;

const ArticleDateTime = styled.div`
  flex-grow: 1;
  
  span {
    margin-right: 5px;
    color: ${colors.text.article};
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 5px;
  }
`;

const ArticleLink = styled.div`
  display: inline-block;
  margin-top: 10px;
`;

interface BlogArticleItemProps {
  article: Blog | HomepageRecommendedBlogPostsItem;
  noIntroduction?: boolean;
  row?: number;
  settings?: NewsSettings;
  langcode: string;
  urls: Array<string>;
  readMoreButtonText?: string;
  heightAuto?: boolean;
}

interface ArticleItemProps {
  row?: number;
  heightAuto?: boolean;
}

export const BlogArticleItem = ({
  article,
  noIntroduction,
  row,
  settings,
  langcode,
  urls,
  readMoreButtonText,
  heightAuto,
}: BlogArticleItemProps) => (
  <ArticleItem row={row} heightAuto={heightAuto}>
    <div>
      {article.image && (
        <ArticleImage {...article.image.childImageSharp} imgStyle={{ objectFit: 'cover' }} />
      )}
    </div>
    <ArticleContent>
      <ArticleCategories>
        {article.categories.map((category, index) => (
          <ArticleCategory
            key={index}
            to={utils.langLink(
              langcode,
              `${urls[15]}/${encodeURI(utils.escapeDiacritics(category.toLocaleLowerCase()))}`
            )}
          >
            {category}
          </ArticleCategory>
        ))}
        {article.tags.map((tag, index) => (
          <ArticleTag
            key={index}
            to={utils.langLink(
              langcode,
              `${urls[15]}/${encodeURI(utils.escapeDiacritics(tag.toLocaleLowerCase()))}`
            )}
          >
            {tag}
          </ArticleTag>
        ))}
      </ArticleCategories>
      <h3>{article.title}</h3>
      {!noIntroduction && <p>{utils.cutArticleIntroduction(article.introduction, 35)}</p>}
      <ArticleDateTime>
        <span>{dateFormat(article.date, 'dd.mm.yyyy')}</span>
        <span>-</span>
        <span>
          <img src={clockIcon} alt="" />
          {article.timeToRead} min.
        </span>
      </ArticleDateTime>

      <ArticleLink>
        <ButtonLink
          extend={true}
          target="_self"
          to={utils.langLink(
            langcode,
            `${urls[15]}/${encodeURI(
              utils.escapeDiacritics(article.categories[0].toLocaleLowerCase())
            )}${article.slug}`
          )}
          icon="arrow-right"
          color="text"
        >
          <span>{settings? settings.button2 : readMoreButtonText}</span>
        </ButtonLink>
      </ArticleLink>
    </ArticleContent>
  </ArticleItem>
);
